import React from "react";

const Tree = () => {
  return (
    <svg viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M4.69321 10.3973H1.37316C1.2112 10.3973 1.08974 10.3253 1.00876 10.1813C0.927784 10.0374 0.936782 9.89792 1.03575 9.76296L3.15465 6.69933H2.65529C2.49334 6.69933 2.37187 6.62735 2.29089 6.4834C2.20992 6.33944 2.21892 6.19998 2.31789 6.06502L5.12509 2.08365C5.20606 1.96669 5.31853 1.9082 5.46249 1.9082C5.60645 1.9082 5.71892 1.96669 5.79989 2.08365L7.08203 3.90563L8.36416 2.08365C8.44514 1.96669 8.5576 1.9082 8.70156 1.9082C8.84552 1.9082 8.95799 1.96669 9.03897 2.08365L11.8462 6.06502C11.9451 6.19998 11.9541 6.33944 11.8732 6.4834C11.7922 6.62735 11.6707 6.69933 11.5088 6.69933H11.0229L13.1283 9.76296C13.2273 9.89792 13.2363 10.0374 13.1553 10.1813C13.0743 10.3253 12.9529 10.3973 12.7909 10.3973H9.48434V11.6389C9.48434 11.8549 9.41011 12.0371 9.26165 12.1855C9.1132 12.334 8.931 12.4082 8.71506 12.4082C8.49912 12.4082 8.31467 12.3317 8.16172 12.1788C8.00876 12.0258 7.93228 11.8414 7.93228 11.6254V10.3973H6.24527V11.6389C6.24527 11.8549 6.17104 12.0371 6.02258 12.1855C5.87412 12.334 5.69192 12.4082 5.47598 12.4082C5.26005 12.4082 5.0756 12.3317 4.92264 12.1788C4.76969 12.0258 4.69321 11.8414 4.69321 11.6254V10.3973ZM9.78126 9.58751H12.0081L9.45735 5.88957H10.6585L8.70156 3.10936L7.62187 4.66142L8.60709 6.06502C8.70606 6.19998 8.71506 6.33944 8.63408 6.4834C8.55311 6.62735 8.43164 6.69933 8.26969 6.69933H7.78383L9.78126 9.58751ZM2.16943 9.58751H8.76904L6.21827 5.88957H7.41943L5.46249 3.10936L3.50555 5.88957H4.7202L2.16943 9.58751ZM2.16943 9.58751H4.7202H3.50555H7.41943H6.21827H8.76904H2.16943ZM9.78126 9.58751H7.78383H8.63408H7.62187H10.6585H9.45735H12.0081H9.78126ZM7.93228 10.3973H9.48434H7.93228Z"
        fill="#333333"
      />
    </svg>
  );
};
export default Tree;
